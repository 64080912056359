import * as React from "react";

type Props = {
  children: React.ReactNode;
};

const Box = ({ children }: Props) => (
  <div className="mt-5 mb-5">{children}</div>
);

export default Box;
