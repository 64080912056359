import * as React from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Box from "@components/Box";
import { StaticImage } from "gatsby-plugin-image";
import ToolLayout from "@layouts/ToolLayout";
import { getWindow } from "@utils/window";
import { H1 } from "@components/Typo";
import { AnyEvent } from "@utils/AnyEvent";

const Page = () => {
  return (
    <ToolLayout title="Wan!Pass（ワンパス） for Business " noIndexNoFollow>
      <Container>
        <H1>証明書事前送付 URLの作成</H1>
      </Container>
      <Container>
        「証明書事前送付」とは、Wan!Passアプリをご利用の飼い主様が、ワクチン・狂犬病の接種証明書データをメール送付できる機能です。
        <br />
        店舗様が飼い主様にお渡しする予約確認などの連絡メールに、以下で作成する専用URLを付けていただくことで、Wan!Passアプリを通してワクチン・狂犬病の接種証明書データの事前送付を行うことができます。
      </Container>
      <Box>
        <Card>
          <Card.Header>必要情報を入力してください</Card.Header>
          <Card.Body>
            <UrlGenForm />
          </Card.Body>
        </Card>
      </Box>
      <Box>
        <Card>
          <Card.Header>「証明書事前送付」とは</Card.Header>
          <Card.Body>
            <Card.Text>
              「証明書事前送付」は、宿泊予約等のシチュエーションで、事前にワクチンなどの証明書を
              Wan!Pass 経由でメール送信できる機能です。
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Wan!Pass アプリのスクリーンショット</Card.Header>
          <Card.Body>
            <Container>
              <Row>
                <Col sm="9" md="6">
                  <StaticImage
                    src="../../images/business/mail-ss-1.png"
                    alt="アプリのスクリーンショット"
                  />
                </Col>
                <Col sm="9" md="6">
                  <StaticImage
                    src="../../images/business/mail-ss-2.png"
                    alt="アプリのスクリーンショット"
                  />
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Box>
    </ToolLayout>
  );
};

const UrlGenForm = () => {
  const defaults = useDefaultsByQuery();
  const [email, setEmail] = React.useState(defaults.email);
  const [facility, setFacility] = React.useState(defaults.facility);
  const onChangeEmail = React.useCallback(
    (e: AnyEvent) => setEmail(e.target.value),
    []
  );
  const onChangeFacility = React.useCallback(
    (e: AnyEvent) => setFacility(e.target.value),
    []
  );

  const [url, setUrl] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    const newUrl =
      (email && facility && generateUrlMessage(email, facility)) || undefined;
    setUrl(newUrl);
  }, [email, facility]);

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>証明書データの送信先メールアドレス</Form.Label>
        <Form.Control
          type="email"
          placeholder="例： pet-hotel@example.com"
          defaultValue={email}
          onChange={onChangeEmail}
          onBlur={onChangeEmail}
          onKeyUp={onChangeEmail}
        />
        <Form.Text className="text-muted">
          飼い主がワクチン・狂犬病の証明書データをメール送信するときの宛先となるメールアドレスです。
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>メールの宛名</Form.Label>
        <Form.Control
          type="text"
          placeholder="例： ◯◯ペットホテル（宿泊予約係）"
          defaultValue={facility}
          onChange={onChangeFacility}
          onBlur={onChangeFacility}
          onKeyUp={onChangeFacility}
        />
        <Form.Text className="text-muted">
          メール本文の先頭に記載する施設名などの宛先です。
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>URL（自動生成）</Form.Label>
        <Form.Control
          as="textarea"
          style={{ fontSize: 14 }}
          rows={5}
          placeholder="送信先メールアドレスと施設名を入力するとURLを生成します"
          value={url || ""}
          onChange={preventDefaultHandler}
          onFocus={selectAllHandler}
          onClick={selectAllHandler}
        />
        <Form.Text className="text-muted">
          上記のURLを事前に飼い主に共有することで、 Wan!Pass
          を経由して証明書のメール送信をすることができます。
          <br />
          コピーしてご利用ください。
        </Form.Text>
      </Form.Group>
    </Form>
  );
};

export default Page;

const URL_BASE = "https://entry.wanpass.me/mail/paper";

const preventDefaultHandler = (e: AnyEvent) => e.preventDefault();
const selectAllHandler = (e: AnyEvent) => e.target.select();

const useDefaultsByQuery = () => {
  const locationSearch = getWindow((w) => w.location.search);
  const defaults = React.useMemo(() => {
    const params = new URLSearchParams(locationSearch || "");
    return {
      email: (params.get("mailto") || "") as string,
      facility: (params.get("facility") || "") as string,
    };
  }, [locationSearch]);
  return defaults;
};

const generateUrlMessage = (email: string, facility: string): string => {
  const params = {
    mailto: email.trim(),
    facility: facility.trim(),
  };

  const url = URL_BASE + "?" + new URLSearchParams(params).toString();
  return (
    "Wan!Pass（ワンパス）をインストール済みの方は以下のURLから証明書をメール送信してください。 \n" +
    url
  );
};
